import axios from 'axios';
import Cookies from 'js-cookie';

/**
 * API module for handling all server requests.
 * 
 * @module api
 */
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true, // Ensure cookies are sent with requests
  timeout: 60000, // 60 seconds timeout
});


let csrfToken = null;

export const fetchCSRFToken = async () => {
  try {
    const response = await api.get('/csrf-token');
    csrfToken = response.data.csrfToken;
    return csrfToken;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
    throw error;
  }
};

api.interceptors.request.use(config => {
  const token = Cookies.get('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  if (csrfToken) {
    config.headers['X-CSRF-Token'] = csrfToken;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  (response) => {
    const token = response.headers['authorization'];
    if (token) {
      Cookies.set('token', token.split(' ')[1], { sameSite: 'Lax', path: '/' });
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.login = async (email, password, rememberMe) => {
  const response = await api.post('/users/login', { email, password, rememberMe });
  if (response.data.user) {
    localStorage.setItem('user', JSON.stringify(response.data.user));
  }
  return response;
};

api.checkAuth = () => api.get('/users/check-auth');

api.logout = () => {
  localStorage.removeItem('token');
  return api.post('/users/logout');
};

api.getUserCards = () => api.get('/cards');
api.addCard = (cardData) => api.post('/cards', cardData);
api.getCardVouchers = (cardId) => api.get(`/vouchers/${cardId}`);
api.addVoucher = (cardId, voucherData) => api.post(`/vouchers/${cardId}`, voucherData);
api.getAllCards = () => api.get('/admin/cards');
api.createCard = (cardData) => api.post('/admin/cards', cardData);
api.updateCard = (cardId, cardData) => api.put(`/admin/cards/${cardId}`, cardData);

api.deleteCard = (cardId) => api.delete(`/admin/cards/${cardId}`);
api.getAllVouchers = () => api.get('/admin/vouchers');
api.createVoucher = (formData) => api.post('/admin/vouchers', formData, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});
api.updateVoucher = (voucherId, formData) => api.put(`/admin/vouchers/${voucherId}`, formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
api.deleteVoucher = (voucherId) => api.delete(`/admin/vouchers/${voucherId}`);
api.assignCardToUser = (cardId, userId) => api.post('/admin/assign-card', { cardId, userId });
api.checkAdminStatus = () => api.get('/admin/check-status', {

});

api.assignCardToUsers = (cardId, email, assignToAll) => 
  api.post('/admin/assign-card', { cardId, email, assignToAll });

api.getAllUsers = () => api.get('/admin/users');
api.getTotalUserCount = () => api.get('/users/count');
api.toggleOrganizerStatus = (userId) => api.post(`/admin/users/${userId}/toggle-organizer`);
api.associateEventWithUser = (userId, eventId) => api.post(`/admin/users/${userId}/associate-event`, { eventId });
api.getUserById = (userId) => api.get(`/admin/users/${userId}`);

api.sendVerificationCode = async (email) => {
  try {
    const response = await api.post('/users/send-verification-code', { email });
    return response;
  } catch (error) {
    console.error('Error sending verification code:', error.response?.data || error.message);
    throw error;
  }
};

api.verifyCode = async (email, code) => {
  return await api.post('/users/verify-code', { email, code });
};

api.getUserPhone = () => api.get('/users/phone');

// Add these lines to the existing api.js file
api.getAllCompanies = () => api.get('/admin/companies');
api.createCompany = (formData) => api.post('/admin/companies', formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

api.updateCompany = (companyId, formData) => api.put(`/admin/companies/${companyId}`, formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  withCredentials: true
});
api.deleteCompany = (companyId) => api.delete(`/admin/companies/${companyId}`);

api.uploadImage = (formData) => {
  console.log('Token before update:', Cookies.get('token'));
  return api.post('/admin/upload-image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
};

export const checkAuth = async () => {
  try {
    const response = await axios.get('/api/users/check-auth', { withCredentials: true });
    if (response.status === 204) {
      return { data: { isAuthenticated: false } };
    }
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return { data: { isAuthenticated: false } };
    }
    throw error;
  }
};

// Add these new functions for event management
api.getAllEvents = async (params = {}) => {
  try {
    const response = await api.get('/events', { params });
    if (response.headers['content-type'].includes('application/json')) {
      return response;
    } else {
      throw new Error('Received non-JSON response');
    }
  } catch (error) {
    console.error('Error in getAllEvents:', error);
    throw error;
  }
};
api.getEventById = (id) => api.get(`/events/${id}`);
api.getEventBySlug = (slug) => api.get(`/events/${slug}`);
api.createEvent = (eventData, config = {}) => api.post('/admin/events', eventData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  ...config,
});
api.updateEvent = (id, eventData, config = {}) => api.put(`/admin/events/${id}`, eventData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  ...config,
});
api.deleteEvent = (id) => api.delete(`/admin/events/${id}`);

api.checkExistingUser = (email, phoneNumber) => api.post('/users/check-existing', { email, phoneNumber });

api.getLandingPageData  = () => api.get('/landing');

api.getOrganizedEvents = () => api.get('/users/organized-events');

api.useTicket = (ticketId) => api.post(`/tickets/use-ticket/${ticketId}`);

// Update the verifyTicket function to include eventId
api.verifyTicket = (publicId, eventId) => api.get(`/tickets/verify-ticket/${publicId}`, { params: { eventId } });

api.processPayment = async (paymentData) => {
  const response = await api.post('/payments/process', paymentData);
  return response.data;
};

api.createPaymentIntent = (paymentData) => api.post('/payments/create-intent', paymentData);

api.createCheckoutSession = (paymentData) => api.post('/payments/create-checkout-session', paymentData);

api.getUserTickets = () => api.get('/users/tickets');

api.getOrder = (sessionId) => api.get(`/orders/${sessionId}`);


api.requestPasswordReset = (email) => api.post('/users/forgot-password', { email });
api.resetPassword = (token, newPassword) => api.post('/users/reset-password', { token, newPassword });

api.createReferralCode = (eventId, code, influencer) => {
  return api.post('/referrals', { eventId, code, influencer });
};
api.trackReferralClick = (code) => {
  return api.get(`/referrals/track/${code}`);
};

// Add this new function
api.verifyTicket = (publicId, eventId) => api.get(`/tickets/verify-ticket/${publicId}`, { params: { eventId } });

api.getUsersByEmail = (email) => api.get(`/users/email/${email}`, {
  headers: {
    'Authorization': `Bearer ${Cookies.get('token')}`
  }
});

api.updateUser = (userId, userData) => api.put(`/users/${userId}`, userData, {
  headers: {
    'Authorization': `Bearer ${Cookies.get('token')}`
  }
});

api.deleteReferralCode = (eventId, referralId) => {
  return api.delete(`/referrals/${eventId}/${referralId}`);
};

api.updateEventStatuses = () => api.post('/events/update-statuses');

api.getEventTicketCount = (eventId) => api.get(`/events/${eventId}/ticket-count`);

export default api;