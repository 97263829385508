import React, { useState, useEffect } from 'react';
import api from '../../../api';

/**
 * Manages user roles and event assignments.
 *
 * @component
 * @returns {React.ReactElement} User management interface.
 */
function UserManagement() {
  const [email, setEmail] = useState('');
  const [user, setUser] = useState(null);
  const [isOrganizer, setIsOrganizer] = useState(false);
  const [organizerEvents, setOrganizerEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [totalUserCount, setTotalUserCount] = useState(0);

  useEffect(() => {
    fetchAllEvents();
    fetchTotalUserCount();
  }, []);

  const fetchAllEvents = async () => {
    try {
      const response = await api.getAllEvents();
      setAllEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const fetchTotalUserCount = async () => {
    try {
      const response = await api.getTotalUserCount();
      setTotalUserCount(response.data.count);
    } catch (error) {
      console.error('Error fetching total user count:', error);
    }
  };

  const handleEmailSearch = async () => {
    try {
      const response = await api.getUsersByEmail(email);
      const userData = response.data;
      if (userData) {
        setUser(userData);
        setIsOrganizer(userData.isOrganizer);
        setOrganizerEvents(userData.organizedEvents || []);
      } else {
        setUser(null);
        alert('User not found');
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      alert('Error fetching user. Please try again.');
    }
  };

  const handleSaveChanges = async () => {
    try {
      const response = await api.updateUser(user._id, {
        isOrganizer,
        organizedEvents: organizerEvents.map(event => event._id),
      });
      const updatedUser = response.data;
      setUser(updatedUser);
      setOrganizerEvents(updatedUser.organizedEvents || []);
      alert('User updated successfully');
    } catch (error) {
      console.error('Error updating user:', error);
      alert('Error updating user');
    }
  };

  const handleRemoveEvent = (eventId) => {
    setOrganizerEvents(organizerEvents.filter(event => event._id !== eventId));
  };

  const handleAddEvent = () => {
    if (selectedEvent) {
      const eventToAdd = allEvents.find(event => event._id === selectedEvent);
      if (eventToAdd && !organizerEvents.some(event => event._id === eventToAdd._id)) {
        setOrganizerEvents([...organizerEvents, eventToAdd]);
      }
      setSelectedEvent('');
    }
  };

  return (
    <div>
      <h2>User Management</h2>
      <div>
        <h3>Total Registered Users: {totalUserCount}</h3>
      </div>
      <div>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter user email"
        />
        <button onClick={handleEmailSearch}>Search</button>
      </div>
      {user ? (
        <div>
          <h3>User Details</h3>
          <p>Name: {user.name}</p>
          <p>Email: {user.email}</p>
          <label>
            <input
              type="checkbox"
              checked={isOrganizer}
              onChange={(e) => setIsOrganizer(e.target.checked)}
            />
            Is Organizer
          </label>
          <h4>Organized Events</h4>
          {organizerEvents.length > 0 ? (
            <ul>
              {organizerEvents.map((event) => (
                <li key={event._id}>
                  {event.title}
                  <button onClick={() => handleRemoveEvent(event._id)}>Remove</button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No organized events yet.</p>
          )}
          <div>
            <select
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e.target.value)}
            >
              <option value="">Select an event</option>
              {allEvents.map((event) => (
                <option key={event._id} value={event._id}>{event.title}</option>
              ))}
            </select>
            <button onClick={handleAddEvent}>Add Event</button>
          </div>
          <button onClick={handleSaveChanges}>Save Changes</button>
        </div>
      ) : (
        <p>No user found. Search for a user to manage their details.</p>
      )}
    </div>
  );
}

export default UserManagement;