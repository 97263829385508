import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from '../../hooks/useTranslation';
import api from '../../api';
import './HomePage.css';
import Header from '../Header/Header';
import AddToHomeScreenPrompt from '../AddToHome/AddToHomeScreenPrompt';
import VoucherDisplay from '../VoucherDisplay/VoucherDisplay';
import AuthPopup from '../AuthPopup/AuthPopup';
import CardDisplay from '../CardDisplay/CardDisplay';
import QRCodePopup from '../QRCodePopup/QRCodePopup';
import VoucherCard from './VoucherCard/VoucherCard';
import TicketCard from '../TicketCard/TicketCard';
import QrCodeIcon from '../../images/qr.svg';  // Add this line
import TrialPopup from '../TrialPopup/TrialPopup';

function HomePage() {
  const { user, setUser, handleLogout } = useOutletContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [tickets, setTickets] = useState([]);
  const [expiredTickets, setExpiredTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [showExpiredTickets, setShowExpiredTickets] = useState(false);
  const [showTrialPopup, setShowTrialPopup] = useState(false);

  const fetchTickets = useCallback(async () => {
    try {
      const response = await api.getUserTickets();

      const now = new Date();
      const validTickets = [];
      const expiredTickets = [];

      response.data.forEach(ticket => {
        const eventDate = new Date(ticket.eventDate);
        const twentyFourHoursAfterEvent = new Date(eventDate.getTime() + 24 * 60 * 60 * 1000);
        
        if (now < twentyFourHoursAfterEvent) {
          validTickets.push(ticket);
        } else {
          expiredTickets.push(ticket);
        }
      });

      setTickets(validTickets);
      setExpiredTickets(expiredTickets);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  }, []);

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    const hasSeenTrialPopup = localStorage.getItem('hasSeenTrialPopup');
    if (!hasSeenTrialPopup) {
      setShowTrialPopup(true);
      localStorage.setItem('hasSeenTrialPopup', 'true');
    }
  }, []);

  const handleCloseTrialPopup = () => {
    setShowTrialPopup(false);
  };

  if (!user) {
    return null; // or a loading indicator
  }

  const [cards, setCards] = useState([]);
  const [vouchers, setVouchers] = useState([]);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [activeTab, setActiveTab] = useState('cards');

  useEffect(() => {
    fetchUserCards();
  }, []);

  useEffect(() => {
    if (selectedVoucher || selectedCard) {
      document.body.classList.add('body-scroll-lock');
    } else {
      document.body.classList.remove('body-scroll-lock');
      window.scrollTo(0, parseInt(document.body.style.top || '0') * -1);
    }
  }, [selectedVoucher, selectedCard]);

  const fetchUserCards = async () => {
    try {
      const response = await api.get('/cards');
      setCards(response.data);
      fetchVouchersForCards(response.data);
    } catch (error) {
      console.error('Error fetching user cards:', error);
    }
  };

  const fetchVouchersForCards = async (cards) => {
    try {
      const voucherPromises = cards.map(card => api.getCardVouchers(card._id));
      const voucherResponses = await Promise.all(voucherPromises);
      const allVouchers = voucherResponses.flatMap(response => {
        if (response && response.data && Array.isArray(response.data)) {
          return response.data.map(voucher => ({
            ...voucher,
            ribbonType: determineRibbonType(voucher),
            company: voucher.company // Ensure company information is included
          }));
        }
        console.warn('Unexpected response format:', response);
        return []; // Return an empty array for invalid responses
      });
      setVouchers(allVouchers);
    } catch (error) {
      console.error('Error fetching vouchers:', error);
    }
  };

  // Add this function to determine the ribbon type based on voucher and card properties
  const determineRibbonType = (voucher) => {
    if (voucher.isVIP) return 'vip';
    if (voucher.isTrial) return 'trial';
    return 'trial';
  };

  const handleVoucherClick = (voucher, event) => {
    event.preventDefault();
    setSelectedVoucher(voucher);
  };

  const handleCloseVoucherDisplay = () => {
    setSelectedVoucher(null);
  };

  const formatExpiryDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
  };

  const handleCloseCardDisplay = () => {
    setSelectedCard(null);
  };

  const handleCloseAuthPopup = () => {
    setShowAuthPopup(false);
    navigate('/');
  };

  const handleLoginSuccess = () => {
    setShowAuthPopup(false);
    navigate('/home');
  };

  const handleTicketClick = (ticket) => {
    setSelectedTicket(ticket);
  };

  const handleCloseQRCodePopup = () => {
    setSelectedTicket(null);
  };

  function ProfileIcon() {
    return (
      <svg width="96" height="96" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
        <circle cx="12" cy="7" r="4" />
      </svg>
    )
  }

  const renderCards = () => (
    Array.isArray(cards) && cards.length > 0 ? (
      cards.map(card => (
        <div key={card._id} className="card" onClick={() => handleCardClick(card)}>
          <h4 className='card-name'>{card.name}</h4>
          <p className="card-number">{card.identificationNumber}</p>
          <h4 className="vouchers">{card.vouchers.length} vouchers</h4>
          <p className="available">available</p>
          <div className='qr-code-icon'>
            <img src={QrCodeIcon} alt="QR Code" />
          </div>
          <p className="valid-date">valid: {formatExpiryDate(card.expiryDate)}</p>
        </div>
      ))
    ) : (
      <p>{t.noCards}</p>
    )
  );

  const renderTickets = () => (
    <div className="tickets-list">
      {tickets.length > 0 ? (
        tickets.map(ticket => (
          <TicketCard
            key={ticket._id}
            ticket={{
              eventTitle: ticket.eventTitle,
              eventDate: ticket.eventDate,
              ticketType: ticket.ticketType.title || 'Unknown Type',
              ticketTypeBadge: ticket.ticketType.badge || 'Unknown Badge',
              status: ticket.status || 'Unknown'
            }}
            onClick={() => handleTicketClick(ticket)}
          />
        ))
      ) : (
        <p>{t.noTickets}</p>
      )}
    </div>
  );

  const renderExpiredTickets = () => (
    <div className="expired-tickets-list">
      <div className="expired-tickets-header">
        <h3 className="expired-tickets-title">Expired Tickets</h3>
        <button 
          className="toggle-expired-tickets"
          onClick={() => setShowExpiredTickets(!showExpiredTickets)}
          aria-label={showExpiredTickets ? "Hide expired tickets" : "Show expired tickets"}
        >
          {showExpiredTickets ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M18 15l-6-6-6 6" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M6 9l6 6 6-6" />
            </svg>
          )}
        </button>
      </div>
      {showExpiredTickets && (
        expiredTickets.length > 0 ? (
          expiredTickets.map(ticket => (
            <TicketCard
              key={ticket._id}
              ticket={{
                eventTitle: ticket.eventTitle,
                eventDate: ticket.eventDate,
                ticketType: ticket.ticketType.title || 'Unknown Type',
                ticketTypeBadge: ticket.ticketType.badge || 'Unknown Badge',
                status: 'Expired'
              }}
              isExpired={true}
              onClick={() => handleTicketClick(ticket)}
            />
          ))
        ) : (
          <p>No expired tickets</p>
        )
      )}
    </div>
  );

  const renderVouchers = () => {
    const voucherCount = vouchers.length;
    let voucherListClass = '';

    if (voucherCount === 1) {
      voucherListClass = 'single-voucher';
    } else if (voucherCount === 2) {
      voucherListClass = 'two-vouchers';
    }

    return (
      <div className={`voucher-list ${voucherListClass}`}>
        {vouchers.map(voucher => (
          <VoucherCard
            key={voucher._id}
            voucher={voucher}
            onClick={(event) => handleVoucherClick(voucher, event)}
            ribbonType={voucher.ribbonType}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="home-page">
      <Header user={user} />
      <main className="profile-main">
        <div className="avatar">
          <ProfileIcon />
        </div>
        <h3 className="user-name">{user.name || 'Christopher Foulkes'}</h3>
      </main>
      <div className="cards-section-background">
        <div className="cards-section">
          <div className="cards-header">
            <button 
              className={`cards-tab ${activeTab === 'cards' ? 'active' : ''}`}
              onClick={() => setActiveTab('cards')}
            >
              {t.yourCards}
            </button>
            <button 
              className={`cards-tab ${activeTab === 'tickets' ? 'active' : ''}`}
              onClick={() => setActiveTab('tickets')}
            >
              {t.tickets}
            </button>
          </div>
          <div className="cards-content">
            <h3 className="cards-title">{activeTab === 'cards' ? t.yourCards : t.tickets}</h3>
            {activeTab === 'cards' ? renderCards() : renderTickets()}
          </div>
        </div>
      </div>
      {activeTab === 'tickets' && expiredTickets.length > 0 && (
        <div className="expired-tickets-section-background">
          <div className="expired-tickets-section">
            <div className="expired-tickets-content">
              {renderExpiredTickets()}
            </div>
          </div>
        </div>
      )}
      {activeTab === 'cards' && (
        <div className="vouchers-section">
          <h2 className='vouchers-title'>{t.vouchers}</h2>
          <p className="vouchers-description">
           {t.vouchersDescription}
          </p>
          {renderVouchers()}
        </div>
      )}
      <div className="coming-soon-section-background">
        {activeTab === 'cards' && (
          <div className="coming-soon-section">
            <div className="coming-soon-icon">🚀</div>
            <p className="coming-soon-text">{t.moreVouchersComingSoon}</p>
          </div>
        )}

        {activeTab === 'tickets' && (
          <div className="coming-soon-section">
            <div className="coming-soon-icon">🎉</div>
            <p className="coming-soon-text">{t.moreEventsComingSoon}</p>
          </div>
        )}
      </div>
      {selectedVoucher && (
        <VoucherDisplay 
          voucher={selectedVoucher} 
          onClose={handleCloseVoucherDisplay}
        />
      )}

      {selectedCard && (
        <CardDisplay 
          card={selectedCard} 
          onClose={handleCloseCardDisplay}
        />
      )}

      {selectedTicket && (
        <QRCodePopup
          ticket={selectedTicket}
          onClose={handleCloseQRCodePopup}
        />
      )}

      <AddToHomeScreenPrompt />
      {user.isAdmin && (
        <div className="admin-link">
          <a href="/admin">{t.adminDashboard}</a>
        </div>
      )}
        
        {showAuthPopup && (
        <AuthPopup
          onClose={handleCloseAuthPopup}
          onLoginSuccess={handleLoginSuccess}
        />
      )}

      {showTrialPopup && <TrialPopup onClose={handleCloseTrialPopup} />}
    </div>
  );
}

export default HomePage;